"use strict";

export var _initSmoothScrolling = function () {
    const distanceToTop = (el) => Math.floor(el.getBoundingClientRect().top);

    function scrollTo(offset, targetAnchor) {
        const fixedOffset = offset.toFixed();
        const onScroll = function () {
            if (window.scrollY.toFixed() === fixedOffset) {
                window.removeEventListener("scroll", onScroll);
                const focusableElements =
                    targetAnchor.querySelectorAll("button, a");
                if (focusableElements.length > 0) {
                    focusableElements[0].focus();
                }
            }
        };

        window.addEventListener("scroll", onScroll);
        onScroll();
        window.scrollTo({
            top: offset,
            behavior: "smooth",
        });
    }

    //Executed on page load with URL containing an anchor tag.
    if (window.location.href.split("#")[1]) {
        const targetID = "#" + window.location.href.split("#")[1];
        const targetAnchor = document.querySelector(targetID);
        if (!targetAnchor) return;
        const offset =
            targetAnchor.getBoundingClientRect().top +
            window.scrollY -
            document.querySelector("header").clientHeight;
        scrollTo(offset, targetAnchor);
    }

    for (let smoothScrollLink of document.querySelectorAll(
        "[data-smoothscroll]"
    )) {
        smoothScrollLink.addEventListener("click", (e) => {
            e.preventDefault();
            const targetID =
                "#" + smoothScrollLink.getAttribute("href").split("#")[1];
            const targetAnchor = document.querySelector(targetID);
            if (!targetAnchor) return;
            const offset =
                targetAnchor.getBoundingClientRect().top + window.scrollY;
            scrollTo(offset, targetAnchor);
            window.history.pushState("", "", targetID);
        });
    }
};
