"use strict";

import { throttle } from "./../helpers.js";
import CarouselSlider from "./carousel-slider.js";

export default class CarouselMultiSlider extends CarouselSlider {
    constructor() {
        super();
    }

    connectedCallback() {
        super.connectedCallback();
    }

    initVars() {
        if (window.innerWidth >= 800) {
            this.slidesPerScreen = 4;
        } else if (window.innerWidth >= 400) {
            this.slidesPerScreen = 3;
        } else {
            this.slidesPerScreen = 2;
        }

        this.numberOfDots = Math.ceil(
            this.numberOfCarouselItems / this.slidesPerScreen
        );
    }

    setupControls() {
        super.setupControls();
    }

    setupCarousel() {
        const _this = this;

        this.siemaElem = new Siema({
            selector: _this.querySelector(".siema"),
            duration: 2500,
            easing: "cubic-bezier(.19,1,.22,1)",
            loop: false,
            perPage: {
                0: 2,
                400: 3,
                800: 4,
            },
            onChange: function () {
                _this.setCurrentSlideClass(this.currentSlide);
            },
        });
    }

    setCurrentSlideClass(currentSlide) {
        this.querySelector(".carousel-slider__item.active").classList.remove(
            "active"
        );
        this.querySelector(".list-dots li.active").classList.remove("active");

        if (currentSlide < 0) {
            currentSlide += this.numberOfCarouselItems;
        }

        this.querySelector(
            ".siema > div > div:nth-child(" +
                (currentSlide + 1) +
                ") .carousel-slider__item"
        ).classList.add("active");
        let activeDot =
            1 +
            Math.floor(
                (currentSlide - 1 + this.slidesPerScreen) / this.slidesPerScreen
            );
        this.querySelector(
            ".list-dots li:nth-child(" + activeDot + ")"
        ).classList.add("active");

        // set previous and next buttons to disabled or active
        if (currentSlide == 0) {
            this.querySelector(".btn-prev").setAttribute("disabled", true);
        } else {
            this.querySelector(".btn-prev").setAttribute("disabled", false);
            this.querySelector(".btn-prev").removeAttribute("disabled");
        }

        if (currentSlide == this.numberOfCarouselItems - this.slidesPerScreen) {
            this.querySelector(".btn-next").setAttribute("disabled", true);
        } else {
            this.querySelector(".btn-next").setAttribute("disabled", false);
            this.querySelector(".btn-next").removeAttribute("disabled");
        }
    }

    resize() {
        super.resize();
    }
}

window.customElements.define("carousel-multi-slider", CarouselMultiSlider);
