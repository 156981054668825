"use strict";

import ModalButton from "./modal-button.js";

export default class ModalButtonVideo extends ModalButton {

    constructor() {
        super();
    }

    connectedCallback() {
        super.connectedCallback();

        this.addEventListener('click', (e) => {
            let modal = document.querySelector('#' + this.getAttribute('data-modal'));
            
            // add video
            modal.querySelector('.embed-responsive').innerHTML = '<iframe id="player" type="text/html" width="640" height="360" src="https://www.youtube-nocookie.com/embed/' +  this.getAttribute('data-youtube-id') + '?enablejsapi=1&autoplay=1" allow="encrypted-media" allowfullscreen frameborder="0" title="' +  this.getAttribute('data-title') + '"></iframe>';
        });
    }
}

window.customElements.define('modal-button-video', ModalButtonVideo);