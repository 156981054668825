"use strict";

import ModalButton from "./modal-button.js";

export default class ModalButtonLightbox extends ModalButton {

    constructor() {
        super();
    }

    connectedCallback() {
        super.connectedCallback();

        this.addEventListener('click', (e) => {
            let modal = document.querySelector('#' + this.getAttribute('data-modal'));
            
            // hide controls if only 1 image
            if(this.classList.contains('gallery__item') ) {
                modal.querySelector('.modal-overlay__controls').style.display = '';
                for (let modalControlButton of modal.querySelectorAll('.modal-overlay__controls button')) {
                    modalControlButton.removeAttribute('disabled');
                }
            } else {
                modal.querySelector('.modal-overlay__controls').style.display = 'none';
                for (let modalControlButton of modal.querySelectorAll('.modal-overlay__controls button')) {
                    modalControlButton.setAttribute('disabled', '');
                }
            }

            // set gallery id as data attribute on modal
            if (this.getAttribute('data-gallery-id')) {
                modal.setAttribute('data-gallery-id', '#' + this.getAttribute('data-gallery-id'));
            } else if (this.closest('.gallery')) {
                modal.setAttribute('data-gallery-id', '#' + this.closest('.gallery').getAttribute('id'));
            }

            // add image
            modal.querySelector('.modal-overlay__img').innerHTML = '<img src="' +  this.getAttribute('data-img-lg') + '" class="img-responsive fullwidth" alt="">';
        });
    }
}

window.customElements.define('modal-button-lightbox', ModalButtonLightbox);