"use strict";


export default class ModalControlsLightbox extends HTMLElement {

    constructor() {
        super();
    }

    connectedCallback() {
        // set modal image when next or previous clicked
        for (let lightboxControlButton of this.querySelectorAll('button')) {

            lightboxControlButton.addEventListener('click', () => {
                if(lightboxControlButton.classList.contains('btn-prev')) {
                    this.setGallerySelected(true);
                } else {
                    this.setGallerySelected(false);
                }
            });
        }

        // set modal image when left or right arrow clicked
        document.querySelector('#modal-lightbox').addEventListener('keydown', (e) => {
            switch(e.which) {
                case 37: // left
                    this.setGallerySelected(true);
                break;
        
                case 39: // right
                    this.setGallerySelected(false);
                break;
        
                default: return; // exit this handler for other keys
            }
        });

        //remove selection on modal close
        /*document.querySelector('#modal-lightbox').addEventListener('hidden.bs.modal', () => {
            document.querySelector('.gallery li.selected').classList.remove('selected');
        });*/
    }

    setGallerySelected(isPrevious) {
        let modal = document.querySelector('#modal-lightbox'),
            gallery = document.querySelector(modal.getAttribute('data-gallery-id')),
            galleryLength = gallery.querySelectorAll('li').length,
            selectedGalleryItem = gallery.querySelector('li.selected'),
            selectedIndex = [...selectedGalleryItem.parentNode.children].indexOf(selectedGalleryItem);

        if(isPrevious) {
            selectedIndex = selectedIndex - 1;
            if (selectedIndex < 0) {
                selectedIndex = galleryLength - 1;
            }
        } else {
            selectedIndex = selectedIndex + 1;
            if (selectedIndex >= galleryLength) {
                selectedIndex = 0;
            }
        }

        selectedGalleryItem = gallery.querySelector('li:nth-child(' + (selectedIndex + 1) + ')');
        
        gallery.querySelector('li.selected').classList.remove('selected');
        selectedGalleryItem.classList.add('selected');

        let img = selectedGalleryItem.getAttribute('data-img-lg'),
            txt = selectedGalleryItem.getAttribute('data-title'),
            caption = '';
            
        caption = selectedGalleryItem.getAttribute('data-caption');
        
        if(selectedGalleryItem.querySelectorAll('.modal-button').length) {
            selectedGalleryItem = selectedGalleryItem.querySelector('.modal-button');
            img = selectedGalleryItem.getAttribute('data-img-lg');
            txt = selectedGalleryItem.getAttribute('data-title');
            caption = selectedGalleryItem.getAttribute('data-caption');
        }

        modal.querySelector('img').setAttribute('src', img);
        modal.querySelector('.modal-overlay__title').textContent = txt;
        modal.querySelector('.modal-overlay__caption').textContent = caption;
    }
}

window.customElements.define('modal-controls-lightbox', ModalControlsLightbox);