"use strict";

// this file is used to control a special textfield in the form editor
// form element: Custom Multi-Checkbox 
export var _initSpecialForm = function() {
    if (window._formIdentifier && window._elementIdentifier) {
        
        const formIdentifier = window._formIdentifier;
        const elementIdentifier = window._elementIdentifier;
        const elementIsRequired = window._elementIsRequired;
        const checkboxes = document.querySelectorAll(`input[name="tx_form_formframework[${formIdentifier}][${elementIdentifier}][]"][type="checkbox"]`);
        const textField = document.getElementById(`${formIdentifier}-${elementIdentifier}-cmc`);


        if(checkboxes && textField) {

            if(elementIsRequired === "1") {
                textField.required = false;
            }

            for( let i = 0; i < checkboxes.length; i++) {

                // for some reason when the element set to required
                // all fields set to required and that's not the behavior we want
                if(elementIsRequired === "1") {
                    checkboxes[i].required = false;
                }

                checkboxes[i].addEventListener('change', function() {

                    // if the last checkbox checked
                    if (i === checkboxes.length - 1 && checkboxes[i].checked) {
                        if(elementIsRequired === "1") {
                            textField.required = true;
                        }

                        // clear the last checkbox name to not have its value... 
                        // ... displayed in the email template
                        // set the textfield name to get its value instead
                        checkboxes[i].name = '';
                        textField.name = `tx_form_formframework[${formIdentifier}][${elementIdentifier}][]`;
                    } 
                    
                    // if the last checkbox unchecked
                    if (i === checkboxes.length - 1 && !checkboxes[i].checked) {
                        if(elementIsRequired === "1") {
                            textField.required = false;
                        }

                        checkboxes[i].name = `tx_form_formframework[${formIdentifier}][${elementIdentifier}][]`;
                        textField.name = '';
                    }
                });
            }
        }
        
    }
}