"use strict";


export var _initForms = function() {
    // typo3 renders the error class directly on the inputs. this is not very good for styling
    //so we will set the error class on the next found form-group element
    for (let formElement of document.querySelectorAll('.f3-form-error, input.has-error, select.has-error, textarea.has-error')) {
        let formElementParentArray = [formElement.parentElement, formElement.parentElement.parentElement, formElement.parentElement.parentElement.parentElement];

        for (let formElementParent of formElementParentArray) {
            if(formElementParent.classList.contains('form-group')) {
                formElementParent.classList.add('has-error');
            }
        }
    }

    for (let fileInputElem of document.querySelectorAll('.file')) {
        let btn = fileInputElem.querySelector('.btn'),
            labelVal = btn.innerHTML;

        fileInputElem.onchange = (e) => {
            let fileName = '';

            let fileLongName = e.target.value;
            fileName = fileLongName.split("\\").pop();
            
            if(fileName) {
                btn.innerHTML = fileName;
            } else {
                btn.innerHTML = labelVal;
            }
        }
    }

    //disable right click
    const disableClicks = document.querySelectorAll('.disable-click')
    disableClicks.forEach(el => el.addEventListener('contextmenu', event => {
        event.preventDefault()
    }))


    //datepicker

            document.querySelectorAll('.date-picker').forEach( input => {
        datepicker( input, {
            id: 1,
            customDays: ['So.', 'Mo.', 'Di.', 'Mi.', 'Do.', 'Fr.', 'Sa.'],
            customMonths: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
            minDate: new Date(),
            formatter: (input, date, instance) => {
            const value = date.toLocaleDateString('de-DE')
                input.value = value // => '1.1.2099'
            }

        });
        })

        document.querySelectorAll('.date-picker-alt').forEach( input => {
        datepicker( input, {
            id: 2,
            customDays: ['So.', 'Mo.', 'Di.', 'Mi.', 'Do.', 'Fr.', 'Sa.'],
            customMonths: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
            minDate: new Date(),
            formatter: (input, date, instance) => {
                const value = date.toLocaleDateString('de-DE')
                input.value = value // => '1.1.2099'
            }
        });
        })
        

        
    /*
     * add class to invalid form inputs and ensure navigation doesn't slide down
     */
    for (let inputElem of document.querySelectorAll('input')) {
        inputElem.addEventListener('invalid', (e) => {
            inputElem.parentElement.classList.add('has-error');
            
            document.querySelector('header .toggle-section--nav').disallowHeaderDown(2000);
        });

        /*
        * remove error class on input if valid 
        */
       inputElem.addEventListener('blur', function() {
            if(inputElem.parentElement.classList.contains('has-error') && inputElem.checkValidity()) {
                inputElem.parentElement.classList.remove('has-error');

                if(inputElem.parentElement.classList.contains('radio')) {
                    console.log('boo');
                    for (let radioElement of inputElem.parentElement.parentElement.querySelectorAll('input')) {

                        radioElement.parentElement.classList.remove('has-error');
                    }
                }
            }
        });
    }

    // Form tracking #35049
    if(document.querySelector(".tracking-form")) {
        let formType = 'contact form'
        if(document.querySelector(".icon-calendar")) {
            formType = 'request form';
        }
        document.querySelector(".tracking-form").addEventListener("submit", function(e){
            if (window.dataLayer) {
                dataLayer.push({
                    'event': 'generate_lead',
                    'form_id': document.querySelector('.tracking-form').id,
                    'form_type': formType
                });
            }
        });
    }

}