"use strict";

export default { 
    screenXxs: 420,
	screenXs: 480,
    screenSm: 768,
    screenMd: 992,
    screenLg: 1200,
    breakpointNav: 1200
}
