"use strict";

import { throttle } from "./../helpers.js";

export default class CarouselSlider extends HTMLElement {
    constructor() {
        super();

        this.siemaElem;
        this.interval = 0;
        this.intervalTiming = 6000;
        this.autoplay = true;
        this.autoplaying = false;
        this.buttonTogglePlay = null;
        this.slidesPerScreen = 1;
        this.numberOfDots = 1;
        this.slides;
    }

    connectedCallback() {
        this.slides = this.querySelectorAll(".carousel-slider__item");
        this.numberOfCarouselItems = this.slides.length;

        // setup siema carousel
        this.setupCarousel();

        // add left right controls
        this.setupControls();

        window.addEventListener("resize", () => {
            throttle(this.resize(), 10);
        });
        this.resize();
    }

    updateAutoplay(shouldPlay) {
        if (this.buttonTogglePlay !== null) {
            if (
                shouldPlay &&
                this.buttonTogglePlay.classList.contains("is-playing")
            ) {
                clearInterval(this.interval);
                this.interval = setInterval(
                    () => this.siemaElem.next(),
                    this.intervalTiming
                );
                this.autoplaying = true;
                //console.log("autoplaying", this.autoplaying, this.interval);
            } else {
                clearInterval(this.interval);
                this.autoplaying = false;
                //console.log("clear interval", this.autoplaying, this.interval);
            }
        } else {
            console.warn("No button to toggle autoplay found");
        }
    }

    initVars() {
        this.numberOfDots = this.numberOfCarouselItems;
    }

    setupControls() {
        this.querySelector(".btn-prev").onclick = () => {
            clearInterval(this.interval);
            this.autoplaying = false;

            this.siemaElem.prev();
            if (this.autoplay === true && this.autoplaying === false) {
                this.updateAutoplay(true);
            }
        };

        this.querySelector(".btn-next").onclick = () => {
            clearInterval(this.interval);
            this.autoplaying = false;

            this.siemaElem.next();
            if (this.autoplay === true && this.autoplaying === false) {
                this.updateAutoplay(true);
            }
        };
    }

    setupDots() {
        const _this = this;

        // add dots
        let dots = this.querySelector(".list-dots");

        if (dots) {
            dots.innerHTML = "";
        } else {
            dots = document.createElement("ul");
            dots.classList.add("list-dots");
            this.appendChild(dots);
        }

        let dot;

        for (let i = 0; i < this.numberOfDots; i++) {
            dot = document.createElement("li");
            dots.appendChild(dot);
            const button = document.createElement("button");
            const buttonText = document.createElement("span");
            if (document.querySelector(":root").hasAttribute("lang", "en")) {
                buttonText.innerHTML = `Jump to slide ${i + 1}`;
            } else {
                buttonText.innerHTML = `Springe zur Slide ${i + 1}`;
            }
            buttonText.classList.add("sr-only");
            button.appendChild(buttonText);
            dot.appendChild(button);

            if (i == 0) {
                dot.classList.add("active");
            }
        }

        for (let dotButton of dots.querySelectorAll("button")) {
            dotButton.addEventListener("click", function () {
                let slideAim = Array.prototype.indexOf.call(
                    dots.childNodes,
                    this.parentElement
                );

                if (_this.classList.contains("carousel-slider--multi")) {
                    slideAim = slideAim * _this.slidesPerScreen;
                }

                _this.siemaElem.goTo(slideAim);
            });
        }
    }

    setupCarousel() {
        const _this = this;

        this.siemaElem = new Siema({
            selector: _this.querySelector(".siema"),
            duration: 2500,
            easing: "cubic-bezier(.19,1,.22,1)",
            loop: true,
            onChange: function () {
                _this.setCurrentSlideClass(this.currentSlide);
            },
        });

        this.querySelector(".siema > div").setAttribute("aria-live", "off");

        if (this.autoplay) {
            this.updateAutoplay(true);

            this.buttonTogglePlay = this.querySelector(".btn-toggle-play");

            if (this.buttonTogglePlay !== null) {
                this.buttonTogglePlay.setAttribute(
                    "aria-label",
                    "Stoppe die automatische Wiedergabe"
                );
                this.buttonTogglePlay.addEventListener("click", () => {
                    if (
                        this.buttonTogglePlay.classList.contains("is-playing")
                    ) {
                        this.updateAutoplay(false);
                        this.buttonTogglePlay.setAttribute(
                            "aria-label",
                            "Starte die automatische Wiedergabe"
                        );
                        this.querySelector(".siema > div").setAttribute(
                            "aria-live",
                            "polite"
                        );
                    } else {
                        this.updateAutoplay(true);
                        this.buttonTogglePlay.setAttribute(
                            "aria-label",
                            "Stoppe die automatische Wiedergabe"
                        );
                        this.querySelector(".siema > div").setAttribute(
                            "aria-live",
                            "off"
                        );
                    }
                });
            }

            const eventListeners = [
                {
                    event: "mouseenter",
                    handler: () => this.updateAutoplay(false),
                },
                { event: "click", handler: () => this.updateAutoplay(false) },
                {
                    event: "touchstart",
                    handler: () => this.updateAutoplay(false),
                },
                {
                    event: "mouseleave",
                    handler: () => this.updateAutoplay(true),
                },
                {
                    event: "keyup",
                    handler: (e) => {
                        if (e.code === "Tab" || e.key === "Tab") {
                            this.updateAutoplay(
                                this.querySelectorAll(":focus").length === 0
                            );
                        }
                    },
                },
                {
                    event: "focusout",
                    handler: (e) => {
                        if (this.contains(e.relatedTarget)) return;

                        this.updateAutoplay(true);
                    },
                },
            ];

            eventListeners.forEach(({ event, handler }) => {
                this.addEventListener(event, handler, { passive: true });
            });
        }
    }

    setCurrentSlideClass(currentSlide) {
        const slideContainers = this.querySelectorAll(".siema > div > div");
        if (this.querySelector(".carousel-slider__item.active")) {
            this.querySelector(
                ".carousel-slider__item.active"
            ).classList.remove("active");
        }
        this.querySelector(".list-dots li.active").classList.remove("active");

        slideContainers.forEach((slideContainer) => {
            slideContainer.setAttribute("aria-hidden", "true");
            slideContainer
                .querySelectorAll(".carousel-slider__item a")
                .forEach((link) => {
                    link.setAttribute("tabindex", "-1");
                });
        });

        if (currentSlide < 0) {
            currentSlide += this.numberOfCarouselItems;
        }

        this.querySelector(
            ".siema > div > div:nth-child(" +
                (currentSlide + 1) +
                ") .carousel-slider__item"
        ).classList.add("active");

        slideContainers[currentSlide + 1].setAttribute("aria-hidden", "false");

        slideContainers[currentSlide + 1]
            .querySelectorAll(" .carousel-slider__item a")
            .forEach((link) => {
                link.setAttribute("tabindex", "");
            });

        this.querySelector(
            ".list-dots li:nth-child(" + (currentSlide + 1) + ")"
        ).classList.add("active");
    }

    resize() {
        const windowWidth = window.innerWidth;

        this.initVars();

        this.setupDots();
        this.setCurrentSlideClass(0);

        this.classList.remove("carousel-slider--no-slide");

        if (
            (this.classList.contains("carousel-slider--multi") &&
                this.numberOfCarouselItems <= this.slidesPerScreen) ||
            (!this.classList.contains("carousel-slider--multi") &&
                this.numberOfCarouselItems <= 1)
        ) {
            this.classList.add("carousel-slider--no-slide");

            if (this.siemaElem) {
                this.siemaElem.destroy(true);
                this.siemaElem = null;
            }
        } else if (!this.siemaElem) {
            this.setupCarousel();
        }

        if (this.autoplay) {
            this.updateAutoplay(true);
        }
    }
}

window.customElements.define("carousel-slider", CarouselSlider);
