"use strict";

import CardElement from "./methods/card-element.js";
import CarouselSlider from "./methods/carousel-slider.js";
import CarouselMultiSlider from "./methods/carousel-multi-slider.js";
import ToggleSection from "./methods/toggle-section.js";
import ToggleSectionNav from "./methods/toggle-section-nav.js";
import TabbedContent from "./methods/tabbed-content.js";
import ModalOverlay from "./methods/modal-overlay.js";
import ModalButton from "./methods/modal-button.js";
import ModalButtonLightbox from "./methods/modal-button-lightbox.js";
import ModalButtonVideo from "./methods/modal-button-video.js";
import ModalControlsLightbox from "./methods/modal-controls-lightbox.js";
import InfoBanner from "./methods/info-banner.js";

import { throttle } from "./helpers.js";
import constants from "./constants.js";
import { _initForms } from "./methods/form.js";
import { _initSpecialForm } from "./methods/special-form.js";
import { _initSmoothScrolling } from "./methods/smoothScrolling.js";

window._thProvider = {
    init: function () {
        _initForms();

        _initSpecialForm();

        _initSmoothScrolling();

        this.lastScrollTop = 0;
        window.addEventListener("scroll", () => {
            throttle(this.scroll(), 80);
        });

        this.initButtonTogglePlay();

        this.scroll();

        this.initAccordion();
    },
    initAccordion: function () {
        window.addEventListener("resize", () => {
            throttle(this.resize(), 10);
        });
        this.resize();

        setTimeout(this.resize, 2000);
    },
    initButtonTogglePlay: function () {
        const playToggleButtons = document.querySelectorAll(".btn-toggle-play");

        playToggleButtons.forEach((playToggleButton) => {
            const presenterVideo = playToggleButton.closest(".presenter-video");

            if (presenterVideo !== null) {
                playToggleButton.setAttribute(
                    "aria-label",
                    "Stoppe die Videowiedergabe"
                );
                playToggleButton.addEventListener("click", () => {
                    playToggleButton.classList.toggle("is-playing");
                    const video = presenterVideo.querySelector("video");
                    if (video !== null) {
                        if (video.paused) {
                            video.play();
                            playToggleButton.setAttribute(
                                "aria-label",
                                "Stoppe die Videowiedergabe"
                            );
                        } else {
                            video.pause();
                            playToggleButton.setAttribute(
                                "aria-label",
                                "Starte die Videowiedergabe"
                            );
                        }
                    }
                });
            } else {
                playToggleButton.addEventListener("click", () => {
                    playToggleButton.classList.toggle("is-playing");
                });
            }
        });
    },
    scroll: function () {
        const currentScrollTop = window.scrollY;
        const btnUp = document.querySelector(".btn-up");

        if (btnUp) {
            btnUp.addEventListener("click", function () {
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            });

            if (
                currentScrollTop <
                document.querySelector("main").clientHeight - window.innerHeight
            ) {
                if (
                    currentScrollTop >= this.lastScrollTop ||
                    currentScrollTop < 200
                ) {
                    btnUp.setAttribute("inert", "");
                } else {
                    btnUp.removeAttribute("inert");
                }
            } else {
                btnUp.removeAttribute("inert");
            }
        }

        this.lastScrollTop = currentScrollTop;
    },
    resize: function () {
        let accordionGroup = document.querySelector(
            ".toggle-section-group--accordion"
        );

        if (accordionGroup) {
            let accordionHeadingLeft = 0;

            if (window.innerWidth >= constants.screenSm) {
                //position accordion buttons
                for (let accordionHeading of accordionGroup.querySelectorAll(
                    ".toggle-section__heading"
                )) {
                    accordionHeading.style.left = `${accordionHeadingLeft}px`;

                    accordionHeadingLeft += accordionHeading.offsetWidth + 8;
                }

                //set first active
                let activeAccordion = accordionGroup.querySelector(
                    ".toggle-section[open]"
                );

                if (activeAccordion) {
                    activeAccordion.removeAttribute("open");
                }

                accordionGroup
                    .querySelector(".toggle-section:first-child")
                    .setAttribute("open", "");

                //set min height
                let minHeight = 0;

                for (let accordionElement of accordionGroup.querySelectorAll(
                    ".toggle-section"
                )) {
                    accordionElement.classList.add(
                        "toggle-section--accordion-desktop"
                    );
                    accordionElement.style.display = "block";

                    minHeight =
                        accordionElement.offsetHeight > minHeight
                            ? accordionElement.offsetHeight
                            : minHeight;

                    accordionElement.style.display = "";
                }

                accordionGroup.style.minHeight = `${minHeight}px`;
            } else {
                for (let accordionElement of accordionGroup.querySelectorAll(
                    ".toggle-section"
                )) {
                    accordionElement.classList.remove(
                        "toggle-section--accordion-desktop"
                    );
                }

                for (let accordionHeading of accordionGroup.querySelectorAll(
                    ".toggle-section__heading"
                )) {
                    accordionHeading.style.left = "";
                }

                accordionGroup.style.minHeight = "";
            }
        }
    },
};

document.addEventListener("DOMContentLoaded", (event) => {
    window._thProvider.init();
});
