"use strict";


export default class CardElement extends HTMLElement {

    constructor() {
        super();
    }

    connectedCallback() {

        let down, up, link = this.querySelector('h3 a');
        this.style.cursor = 'pointer';
        this.onmousedown = () => down = +new Date();
        this.onmouseup = () => {
            up = +new Date();
            if ((up - down) < 200) {
                link.click();
            }
        }
    }
}

window.customElements.define('card-element', CardElement);