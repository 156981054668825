"use strict";
import constants from "./../constants.js";
import { throttle } from "./../helpers.js";

import ToggleSection from "./toggle-section.js";

export default class ToggleSectionNav extends ToggleSection {
    constructor() {
        super();

        this.lastScrollTop = 0;
        this.delta = 10;
        this.navbarHeight = document.querySelector("header").clientHeight;
        this.headerElement = document.querySelector("header");
        this.focusableElements = [];
        this.domLoaded = false;
    }

    connectedCallback() {
        super.connectedCallback();

        this.focusableElements = this.headerElement.querySelectorAll(
            'a:not([tabindex="-1"]), button:not([tabindex="-1"]), input'
        );
        document.addEventListener("keyup", (e) => {
            if (e.code === "Tab" || e.key === "Tab") {
                if (
                    this.hasAttribute("open") &&
                    document.querySelectorAll(
                        "header a:focus, header button:focus, header input:focus"
                    ).length === 0 &&
                    window.innerWidth < constants.breakpointNav
                ) {
                    this.removeAttribute("open");
                }

                // if submenu open, but focus is on first level of navigation, close it
                if (
                    this.querySelector(".toggle-section--menu[open]") &&
                    !e.target.parentElement.parentElement.closest(
                        ".toggle-section--menu"
                    )
                ) {
                    this.querySelector(
                        ".toggle-section--menu[open]"
                    ).removeAttribute("open");
                }
            }
        });

        document.querySelector("body").addEventListener("click", () => {
            const openDropdown = this.querySelectorAll(
                ".toggle-section--menu[open]"
            );
            if (
                openDropdown.length > 0 &&
                document.querySelectorAll("header:hover, header:focus-within")
                    .length === 0 &&
                window.innerWidth >= constants.breakpointNav
            ) {
                openDropdown.forEach((el) => {
                    el.removeAttribute("open");
                });
            }
        });

        window.addEventListener("resize", () => {
            throttle(this.resize(), 10);
        });
        this.resize();

        window.addEventListener("scroll", () => {
            throttle(this.scroll(), 80);
        });

        document.addEventListener("readystatechange", (event) => {
            if (event.target.readyState === "complete") {
                this.domLoaded = true;
                document
                    .querySelector(":root")
                    .style.setProperty(
                        "--header-height",
                        `${this.headerElement.offsetHeight}px`
                    );
            }
        });

        this.scroll();
    }

    // When `open` changes value, execute switchState()
    attributeChangedCallback(name) {
        super.attributeChangedCallback(name);
    }

    switchState() {
        super.switchState();

        //close any open panels
        for (let toggleSection of this.querySelectorAll(".toggle-section")) {
            toggleSection.removeAttribute("open");
        }

        //TEMPORARY FIX "position:fixed ->relative"

        if (this.hasAttribute("open")) {
            //fix background so does not scroll if hamburger menu open
            if (window.innerWidth < constants.breakpointNav) {
                let windowY = window.scrollY;
                document.body.style.position = "fixed";
                document.body.style.left = "0";
                document.body.style.right = "0";
                document.body.style.top = `-${windowY}px`;
            }

            //document.querySelector('.header').classList.add('header--open');
        } else {
            if (window.innerWidth < constants.breakpointNav) {
                const scrollY = document.body.style.top;
                document.body.style.position = "";
                document.body.style.top = "";
                window.scrollTo(0, parseInt(scrollY || "0") * -1);
            }
            //document.querySelector('.header').classList.remove('header--open');
        }
    }

    scroll() {
        const currentScrollTop = window.scrollY;

        // Make sure page scrolled more than delta
        if (Math.abs(this.lastScrollTop - currentScrollTop) <= this.delta)
            return;

        // If page scrolled down and past the navbar, add class nav-up.
        // This is necessary so you never see what is "behind" the navbar.
        if (
            currentScrollTop > this.lastScrollTop &&
            currentScrollTop > this.navbarHeight
        ) {
            // Scroll Down
            this.headerElement.setAttribute("aria-hidden", "true");
            this.focusableElements.forEach((el) => {
                el.setAttribute("tabindex", "-1");
            });

            // close any open dropdowns
            const openDropdown = this.querySelector(".toggle-section[open]");
            if (openDropdown) {
                openDropdown.removeAttribute("open");
            }
        } else {
            if (
                currentScrollTop + window.innerHeight <
                document.body.clientHeight
            ) {
                if (currentScrollTop < 20) {
                    this.headerElement.removeAttribute("aria-hidden");
                } else if (
                    this.headerElement.getAttribute("data-disable-nav-down") !=
                    "true"
                ) {
                    this.headerElement.setAttribute("aria-hidden", "false");
                }
            }
            this.focusableElements.forEach((el) => {
                el.removeAttribute("tabindex");
            });
        }

        this.lastScrollTop = currentScrollTop;
    }

    resize() {
        if (window.innerWidth >= constants.breakpointNav) {
            this.setAttribute("open", "");
            this.btn.setAttribute("aria-hidden", "true");
            document.body.style.position = "";
            document.body.style.top = "";
        } else {
            this.removeAttribute("open");
            this.btn.removeAttribute("aria-hidden");
        }

        if (this.domLoaded) {
            document
                .querySelector(":root")
                .style.setProperty(
                    "--header-height",
                    `${this.headerElement.offsetHeight}px`
                );
        }
    }

    disallowHeaderDown(time) {
        const headerElement = document.querySelector("header");

        if (headerElement.getAttribute("data-disable-nav-down") != "true") {
            clearTimeout(this.disallowTimer);

            headerElement.setAttribute("aria-hidden", "true");
            headerElement.setAttribute("data-disable-nav-down", "true");

            // re-allow navigation slide down after 2 seconds
            this.disallowTimer = setTimeout(function () {
                headerElement.setAttribute("data-disable-nav-down", "false");
            }, time);
        }
    }
}

window.customElements.define("toggle-section-nav", ToggleSectionNav);
