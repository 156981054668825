"use strict";

import { throttle } from "./../helpers.js";
import constants from "./../constants.js";

export default class ToggleSection extends HTMLElement {
    constructor() {
        super();

        this.setAttribute("role", "region");
        this.heading = this.querySelector(".toggle-section__heading");
        if (this.heading.querySelector("button")) {
            this.btn = this.heading.querySelector("button");
        } else {
            this.btn = this.heading;
        }
        this.content = this.querySelector(".toggle-section__content");

        //initialise element
        this.switchState();
    }

    connectedCallback() {
        this.btn.onclick = (e) => {
            //close others
            this.closeOpenToggleSection();

            // Change the component's `open` attribute value on click
            let open = this.hasAttribute("open");

            if (open) {
                this.removeAttribute("open");
            } else {
                this.setAttribute("open", "");
            }
        };

        this.onkeydown = (evt) => {
            if (!this.classList.contains("toggle-section--nav")) {
                let isEscape = false;

                if ("key" in evt) {
                    isEscape =
                        evt.key === "Escape" ||
                        evt.key === "Esc" ||
                        evt.key === "Backspace";
                } else {
                    isEscape = evt.keyCode === 27;
                }

                if (isEscape) {
                    this.removeAttribute("open");
                }
            }
        };

        window.addEventListener("resize", () => {
            throttle(this.resize(), 10);
        });
        this.resize();
    }

    // Identify just the `open` attribute as an observed attribute
    static get observedAttributes() {
        return ["open"];
    }

    // When `open` changes value, execute switchState()
    attributeChangedCallback(name) {
        if (name === "open") {
            this.switchState();
        }
    }

    switchState() {
        let expanded = this.hasAttribute("open");

        // Toggle `aria-expanded`
        this.btn.setAttribute("aria-expanded", expanded);

        // Toggle the `.content` element's visibility
        this.content.setAttribute("aria-hidden", !expanded);

        // calc max open height
        if (expanded) {
            this.content.style.maxHeight = `${this.content.scrollHeight}px`;
        } else {
            this.content.style.maxHeight = "";
        }

        // Toggle the `.content` link element's tabbability
        // being careful not to include links that are within a further toggle section
        const linkElementsToQuery = this.content.querySelectorAll("a, button");

        for (let panelCollapseLink of linkElementsToQuery) {
            if (
                panelCollapseLink.closest(".toggle-section__content") ==
                this.content
            ) {
                if (expanded) {
                    panelCollapseLink.setAttribute("tabindex", "");
                } else {
                    panelCollapseLink.setAttribute("tabindex", "-1");
                }
            }
        }
    }

    closeOpenToggleSection() {
        let toggleSectionGroup = this.closest(".toggle-section-group");

        if (toggleSectionGroup) {
            let currentOpened = toggleSectionGroup.querySelector(
                ".toggle-section[open]"
            );

            if (currentOpened && currentOpened != this) {
                currentOpened.removeAttribute("open");
            }
        }
    }

    resize() {
        // calc max open height
        if (this.hasAttribute("open")) {
            this.content.style.maxHeight = `${this.content.scrollHeight}px`;
        }

        //initialise element
        this.switchState();
    }
}

window.customElements.define("toggle-section", ToggleSection);
