"use strict";


export default class ModalButton extends HTMLElement {

    constructor() {
        super();

        this.setAttribute('tabindex', '0');
        this.setAttribute('role', 'button');
        this.style.cursor = "pointer";
    }

    connectedCallback() {
        this.addEventListener('click', (e) => {
            e.preventDefault();

            let id = (this.getAttribute('data-modal')) ? this.getAttribute('data-modal') : 'modal-lightbox';
            let modal = document.querySelector('#' + id);
            modal.open(this);
            
            // add title
            if (this.getAttribute('data-title')) {
                modal.querySelector('.modal-overlay__title').textContent = this.getAttribute('data-title');
            } else if (this.parentElement.querySelector('figcaption')) {
                modal.querySelector('.modal-overlay__title').textContent = this.parentElement.querySelector('figcaption').textContent;
            } else {
                modal.querySelector('.modal-overlay__title').textContent = '';
            }

            // add caption
            if (this.getAttribute('data-caption')) {
                modal.querySelector('.modal-overlay__caption').textContent = this.getAttribute('data-caption');
            } else {
                modal.querySelector('.modal-overlay__caption').textContent = '';
            }
        });
        
        this.addEventListener('keyup', (e) => {
            if (e.keyCode === 13) {
                this.click();
            }
        });
    }
}

window.customElements.define('modal-button', ModalButton);