"use strict";


export default class TabbedContent extends HTMLElement {

    constructor() {
        super();
    }

    connectedCallback() {
        // Get relevant elements and collections
        const tablist = this.querySelector('.tabbed-content__nav');
        const tabs = tablist.querySelectorAll('a');
        const panels = this.querySelectorAll('.tabbed-content__pane');
        const panelssmall = this.querySelectorAll('.tabbed-content__pane-sm');

        // The tab switching function
        const switchTab = (oldTab, newTab) => {
            newTab.focus();
            // Make the active tab focusable by the user (Tab key)
            newTab.removeAttribute('tabindex');
            // Set the selected state
            newTab.setAttribute('aria-selected', 'true');
            oldTab.removeAttribute('aria-selected');
            oldTab.setAttribute('tabindex', '-1');
            // Get the indices of the new and old tabs to find the correct
            // tab panels to show and hide
            let index = Array.prototype.indexOf.call(tabs, newTab);
            let oldIndex = Array.prototype.indexOf.call(tabs, oldTab);
            panels[oldIndex].hidden = true;
            panels[index].hidden = false;

        }

        // Add the tablist role to the first <ul> in the .tabbed container
        tablist.setAttribute('role', 'tablist');

        // Add semantics are remove user focusability for each tab
        Array.prototype.forEach.call(tabs, (tab, i) => {
            tab.setAttribute('role', 'tab');
            tab.setAttribute('id', 'tab' + (i + 1));
            tab.setAttribute('tabindex', '-1');
            tab.parentNode.setAttribute('role', 'presentation');

            // Handle clicking of tabs for mouse users
            tab.addEventListener('click', e => {
              e.preventDefault();
              let currentTab = tablist.querySelector('[aria-selected]');
              if (e.currentTarget !== currentTab) {
                switchTab(currentTab, e.currentTarget);
              }
            });

            // Handle keydown events for keyboard users
            tab.addEventListener('keydown', e => {
              // Get the index of the current tab in the tabs node list
              let index = Array.prototype.indexOf.call(tabs, e.currentTarget);

              // Determine arrow key pressed
              var dir = e.which === 37 ? index - 1 : e.which === 39 ? index + 1 : null;

              // Switch to the new tab if it exists
              if (dir !== null) {
                e.preventDefault();

                // Find correct tab to focus
                let newIndex;
                if (tabs[dir]) {
                  newIndex = dir;
                } else {
                  // Loop around if adjacent tab doesn't exist
                  newIndex = dir === index - 1 ? tabs.length - 1 : 0;
                }
                switchTab(e.currentTarget, tabs[newIndex]);
                tabs[newIndex].focus();
              }
            });
        });

        // Add tab panel semantics and hide them all
        Array.prototype.forEach.call(panels, (panel, i) => {
            panel.setAttribute('role', 'tabpanel');
            panel.setAttribute('tabindex', '-1');
            let id = panel.getAttribute('id');
            panel.setAttribute('aria-labelledby', tabs[i].id);
            panel.hidden = true;
        });

        // Initially activate the first tab and reveal the first tab panel
        tabs[0].removeAttribute('tabindex');
        tabs[0].setAttribute('aria-selected', 'true');
        panels[0].hidden = false;

    }
}


window.customElements.define('tabbed-content', TabbedContent);