/**
 * Throttles functions, useful for on scroll
 */
export var throttle = function(callback, limit) {
    var tick = false;
    return function () {
        if (!tick) {
          callback.call();
          tick = true;
          setTimeout(function () {
            tick = false;
          }, limit);
        }
    }
}

export var isMobileDevice = () => {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
};