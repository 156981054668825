"use strict";


export default class ModalOverlay extends HTMLElement {

    constructor() {
        super();

        this.currentModalButton;
    }

    connectedCallback() {
        this.querySelector('.close').addEventListener('click', () => { 
            this.close(); 
        });

        window.addEventListener('click', (e) => {
            if(this.hasAttribute('open')) {
                if(e.target === this) {
                    this.close();
                }
            }
        });

        const focusableElementsString = "a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]";
        
        this.onkeydown = (evt) => {
            let isEscape = false;

            if ("key" in evt) {
                isEscape = (evt.key === "Escape" || evt.key === "Esc" || evt.key === "Backspace");
            } else {
                isEscape = (evt.keyCode === 27);
            }
            if(isEscape) {
                this.close();
            }

            // if tab or shift-tab pressed
            if (evt.which == 9) {

                // get list of all children elements in given object
                let o = this.querySelectorAll('*');

                let focusableItems = this.querySelectorAll(focusableElementsString);

                // get the number of focusable items
                let numberOfFocusableItems = focusableItems.length

                // get the index of the currently focused item
                let focusedItemIndex = 0;
                for (let i = 0; i < focusableItems.length; i++) {
                    if (focusableItems[i] === document.activeElement) {
                        focusedItemIndex = i;
                    }
                }

                if (evt.shiftKey) {
                    //back tab
                    // if focused on first item and user preses back-tab, go to the last focusable item
                    if (focusedItemIndex == 0) {
                        focusableItems[numberOfFocusableItems - 1].focus();
                        evt.preventDefault();
                    }

                } else {
                    //forward tab
                    // if focused on the last item and user preses tab, go to the first focusable item
                    if (focusedItemIndex == numberOfFocusableItems - 1) {
                        focusableItems[0].focus();
                        evt.preventDefault();
                    }
                }
            }
        }
    }

    static get observedAttributes() {
        return ['open'];
    }

    attributeChangedCallback(name) {
        if (name === 'open') {
            this.switchState();
        }
    }

    switchState() {
        let expanded = this.hasAttribute('open');

        this.setAttribute('aria-hidden', !expanded);


        if(expanded) {
            this.focus();
            document.querySelector('body').classList.add('modal-open');
        } else {
            document.querySelector('body').classList.remove('modal-open');
            
            //remove video on modal close
            let iframeElem = this.querySelector('iframe');
            if(iframeElem) {
                iframeElem.parentElement.innerHTML = "";
            }

            // focus old button on close after delay, so keypress event on button isn't triggered
            setTimeout(() => { this.currentModalButton.focus(); }, 200);
        }
    }

    open(currentButton) {
        this.currentModalButton = currentButton;
        this.setAttribute('open', 'true');
    }

    close() {
        this.toggleAttribute('open');
    }
}

window.customElements.define('modal-overlay', ModalOverlay);