"use strict";

export default class InfoBanner extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.intersectionRatio > 0) {
                        this.classList.add("info-banner--visible");
                    } else {
                        this.classList.remove("info-banner--visible");
                    }
                });
            },
            {
                rootMargin: "-30% 0px 0px 0px",
            }
        );
        observer.observe(this);
    }
}

window.customElements.define("info-banner", InfoBanner);
